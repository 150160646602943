export default [
    {
        path: '/admin/applications',
        name: 'admin-applications',
        component: () => import('@/modules/Admin/Pages/ApplicationsPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.photos.applications',
            breadcrumb: [
                { title: 'admin.photos.applications', url: `/admin/applications`, active: true }
            ]
        },
    },
]
